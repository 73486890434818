import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const PageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicPage || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }


  return (
    <Layout activeDocMeta={activeDoc} seo={seo}>
      <div className="custom-header" >
        <div style={{height:"76px"}}></div>
          <div className="container py-5">
            
              <h3 className="text-white mb-0">{homepage.data.page_title}</h3>
          </div>
      </div>
      <div className="container py-5">
        <SliceZone slices={homepage.data.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        seo_title
        seo_description
        seo_keywords
        page_title 
        body { 
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
					...PageDataBodyTitleAndText
          ...PageDataBodyEmbeddedHtml
        }
      }
    }
  }
`

export default withPrismicPreview(PageTemplate)
